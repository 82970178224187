import React from 'react' 
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledUnderline = styled.div`
  width: 50px;
  height: 4px;
  background: #2c5282;
  margin-top: 80px;
  margin-bottom: 22px;
`

const PageResidentialSection = () => (  
  <StaticQuery 
    query={query}
    render={
      data => {
        const imageDataOne = data.imageOne.childImageSharp.fluid
        const imageDataThree = data.imageThree.childImageSharp.fluid

        return (
          <div className='bg-gray-200'>
            <div className='lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8'>
        
              <h2 className='font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-6'>Interpacific Asset Management is experienced in the full-service management of single family homes, multifamily homes, individual condominiums and townhomes.</h2>

              <div className='lg:flex block'>
                <div className='flex flex-col items-center w-1/2 sm:w-full p-6 sm:p-0'>
                  <Img fluid={imageDataOne} alt='IREM logo' className='w-full mb-12' />
                </div>

                <div className='flex flex-col items-center w-1/2 sm:w-full p-6 sm:p-0'>
                  <Img fluid={imageDataThree} alt='AMO logo' className='w-full' />
                </div>
              </div>
        
              <StyledUnderline></StyledUnderline>
        
              <h3 className='font-display text-gray-900 text-2xl sm:text-xl leading-snug mb-8'>Our Management Program Includes:</h3>
        
              <ul className='flex flex-wrap justify-between p-4'>
                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Interfacing with tenants and owners</span>
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Collection of all rents and expense charges</span>
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Coordinating and following up with service and maintenance requests</span>
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>If requested, providing referrals for contractors and vendors who are licensed, bonded, insured, and priced competitively.</span>
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>We will arrange for eviction of any tenants who have failed to meet their rental obligation and act as liaison for legal counsel in this process.</span>
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>If required, we offer leasing services, including marketing your property for lease, screening prospective tenants, creating new lease/rental agreements, and negotiating lease renewals.</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )
      }
    }
  />      
)

export default PageResidentialSection

const query = graphql`
  query PageResidentialSectionQuery {
    imageOne: file(relativePath: { eq: "logos/irem.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "logos/amo-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`