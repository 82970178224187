import React from "react"
import { graphql } from 'gatsby'
import PageContainer from "../containers/PageContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBg from '../headers/PageHeaderImageBg'
import PageHeaderText from "../headers/PageHeaderText"
import HeaderNav from "../navigation/HeaderNav"
import PageResidentialSection from "../sections/PageResidentialSection"
import HomeAffiliationSection from "../sections/HomeAffiliationSection"
import CTASection from "../sections/CTASection"

export default ({ data }) => (
  <PageContainer>
    <HeadData 
      title='Residential Services' 
      description="Interpacific Asset Management's full service management program includes interfacing with tenants and owners, collection of all rents and expense charges, coordinating and following up with service and maintenance requests."
    />

    <PageHeaderImageBg imageData={data.imageBg.childImageSharp.fluid}>
      <HeaderNav />
      <PageHeaderText
        title='Residential Services'
      />
    </PageHeaderImageBg>

    <PageResidentialSection />

    <HomeAffiliationSection />

    <CTASection />
  </PageContainer>
)

export const query = graphql`
  query ResidentialServicesPageQuery {
    imageBg: file(relativePath: { eq: "services/home-service-residential-bg-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
